@import "_mixins.scss";

$borderColor: var(--mui-palette-background-secondary);
$sidebarWidth: 250px;
$sidebarMiniWidth: 60px;
$borderRadius: 7px;
$fontSize: 0.875rem;
$sm: 600px;
$md: 960px;
$lg: 1200px;
$space2: 21px;
$space: 16px;
$status0Color: #000000;
$status1Color: #ffffff;
$status2Color: #ef5350;
$status3Color: #75a478;
$status4Color: #f39c11;
$status5Color: #547afb;
$rowProposeColor: #ffff00;
$rowUnmatchColor: #ff0000;
$rowInfoColor: #c8c8c8;
$rowIgnoreColor: #008000;

/**************
lg, large: 1280px
xl, extra-large: 1920px
*************/
.shadow {
    &-base {
        @include border-radius(0);
    }

    box-shadow: 0px 0px 8px rgba(12, 37, 86, 0.22);
    @extend .shadow-base;

    &-sm {
        box-shadow: 0px 0px 4px rgba(12, 37, 86, 0.12);
        @extend .shadow-base;
    }
}

.display-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.flex {
    &-grow-1 {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
    }
    &-column {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    &-row {
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

.full-height {
    @extend .flex-grow-1;

    &-container {
        @extend .flex-grow-1;
        @extend .display-flex;
        @extend .flex-column;
    }
}
.MuiAccordion-root {
    @extend .shadow-sm;
}

.title {
    font-size: 25px;
    font-weight: 700;
}

.MuiCard-root {
    margin-bottom: $space; 

    .MuiCardContent-root {
        padding: $space2;
    }
    .MuiCardActions-root {
        padding: $space2;
    }

    .MuiCardHeader-root {
        color: var(--mui-palette-text-primary);

        .MuiCardHeader-action {
            margin-right: 0;
        }
    }

    &.card-main {
        box-shadow: none;
        .MuiCardHeader-root {
            padding-left: 0;
            padding-right: 0;

            .description {
                color: var(--mui-palette-text-secondary);
                p {
                    margin-top: 8px;
                }
            }
        }
        
    }

    &.card-sub {
        @include border-radius($borderRadius);
        margin-left: -11px;
        margin-right: -11px;

        .MuiCardContent-root, .MuiCardHeader-root {
            padding: 11px;
        }
        
    }  
}

.input {
    &-dropdown2 {
        color: #006064;
    }
    &-min {
        min-width: 150px;
    }
}

.icon {
    &-custom {
        font-size: 1rem;
    }
}

.stroke {
    &-default {
        stroke: var(--mui-palette-text-primary);
    }
}

.fill {
    &-default {
        fill: var(--mui-palette-text-primary);
    }
}

.divider-btn {
    height: 30px;
}

.btn {
    &-icon {
        min-width: 44px;

        &:before,&:after {
            content: "";
            display: block;
        }
    }

    &-icon-sm {
        padding: 0;
        font-size: 18px;
        width: 30px;
        height: 30px;

        .icon {
            @extend .icon-default;
        }

        &:hover {
            color: white;
        }
    }
    &-action {
        color: var(--mui-palette-text-secondary);

        .MuiAvatar-root {
            color: inherit;
            background-color: var(--mui-palette-secondary-main);
        }
    }

    &-link {
        color: var(--mui-palette-primary-main);
        font-weight: 500;
    }
}

.MuiButton-root {
    @include border-radius($borderRadius);
    text-transform: none;

    &.MuiButton-containedPrimary {
        .stroke-default {
            stroke: #ffffff;
        }
    }
}

.MuiButtonGroup-root {
    @include border-radius($borderRadius);

    &.btn-group-status {
        background-color: var(--mui-palette-background-secondary);
        padding: 5px;

        .MuiButtonGroup-grouped {
            padding-left: 15px;
            padding-right: 15px;
            color: var(--mui-palette-text-secondary);

            &:not(:first-of-type) {
                border-top-left-radius: $borderRadius;
                border-bottom-left-radius: $borderRadius;
            }
            
            &:not(:last-of-type) {
                border-right: none;
                margin-right: 5px;
                border-top-right-radius: $borderRadius;
                border-bottom-right-radius: $borderRadius;
            }
        }
    }
}

.p {
    &-0 {
        padding: 0 !important;
    }

    &l-0 {
        padding-left: 0 !important;
    }

    &r-0 {
        padding-right: 0 !important;
    }
}

.mb {
    &-0 {
        margin-bottom: 0 !important;
    }
    &-space {
        margin-bottom: $space;
    }
}

.mt {
    &-0 {
        margin-top: 0 !important;
    }
    &-space {
        margin-top: $space;
    }
}

.MuiTable-root {
    .MuiTableCell-root {
        @extend .text-default;
        border-color: $borderColor;
        padding: 6px 15px;

        &.MuiTableCell-head {
            color: var(--mui-palette-text-secondary);
            white-space: nowrap;
            text-transform: uppercase;
        }
    }
}

.MuiTablePagination-root {
    background-color: var(--mui-palette-background-third);
    @include border-radius($borderRadius);
    margin-top: $space;

    .MuiTablePagination-toolbar {
        @media (min-width: $sm) {
            min-height: 45px;
        }

        .MuiTablePagination-selectLabel,.MuiTablePagination-displayedRows, .MuiInputBase-root {
            @extend .text-default;
        }
    }
}
.line-through {
    text-decoration: line-through;
}

.row-gray .MuiTableCell-root {
    color: gray;
}

.text {
    &-default {
        font-size: 0.8125rem;
    }
    &-right {
        text-align: right;
    }
}

.MuiInputBase-root {
    @include border-radius($borderRadius);
    background: rgba(255, 255, 255, 0.06);

    .MuiInputBase-input {
        color: var(--mui-palette-text-primary);
        @extend .text-default;
        padding-top: 10px;
        padding-bottom: 10px;
        height: auto;
        
        &::placeholder {
            color: var(--mui-palette-text-secondary);
        }
        &[readonly] {
            background-color: var(--mui-palette-grey-100);
        }
    }
    &.MuiInputBase-multiline {
        padding-top: 10px;
        padding-bottom: 10px;

        .MuiInputBase-input {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &.MuiOutlinedInput-root {

        &.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
                border-width: 1px;
            }
        }

        .MuiInputAdornment-root {
            margin-left: -14px;
            .MuiInputBase-root {
                border-radius: 0;
                &::before,&::after {
                    border-bottom-color: transparent;
                }

                fieldset {
                    border-left-color: transparent;
                    border-top-color: transparent;
                    border-bottom-color: transparent;
                }
            }
        }
    }
}

.cell {
    &-action {
        max-width: 100px;
        width: 100px;
        label {
            margin-right: 0;
        }
    }
    &-select {
        max-width: 50px;
        width: 50px;
        label {
            margin-right: 0;
        }
    }
    &-switch {
        max-width: 90px;
        width: 90px;
    }

    &-error {
        .MuiOutlinedInput-root {
            .MuiOutlinedInput-notchedOutline {
                border-color: red;
            }
        }
    
        &:before {
            content: attr(title);
            font-size: 10px;
            line-height: 1;
            background-color: red;
            color: #ffffff;
            padding: 3px;
            position: absolute;
            z-index: 1;
            margin-top: -8px;
            margin-left: -3px;
        }
    }
}

.select-dropdown {
    .MuiInputBase-root {
        background: transparent;
        &::before {
            border-bottom-width: 1px !important;
        }
        .MuiInputBase-input {
            background: transparent;
        }

        .MuiSelect-icon {
            font-size: 1.125rem;
            top: 50%;
            right: 0;
            @include translate(0, -50%);
        }
    }

    .MuiInput-root:after {
        border-width: 0;
    }

    &.select-dropdown-1 {
        .MuiInputBase-root {
            &::before {
                border-bottom-width: 0 !important;
            }
        }
    }
}
.icon-default-stroke {
    stroke-width: 1.5;
}
.icon-default {
    @extend .icon-default-stroke;
    width: 1.3rem;
    height: 1.3rem;
}
.list-default-width {
    min-width: 207px;

    &-sm {
        min-width: 160px;
    }
}
.list-default {
    @extend .list-default-width;
    .MuiListItem-root {
        padding-top: 5px;
        padding-bottom: 5px;
        .MuiListItemIcon-root {
            min-width: 40px;
            color: var(--mui-palette-text-secondary);

            .icon {
                @extend .icon-default;
            }
        }
        .MuiListItemText-root {
            color: var(--mui-palette-text-primary);
        }

        &:hover {
            .MuiListItemIcon-root {
                color: var(--mui-palette-primary-main);
            }
        }
    }
}
.MuiMenuItem-root {
    padding-top: 9px;
    padding-bottom: 9px;
}
.dropdown {
    @extend .shadow;

    .MuiCardContent-root {
        padding: 0;
    }
}
.MuiList-root {
    @extend .list-default;
}
.MuiMenu-paper {
    @extend .dropdown;
    .MuiList-root {
        @extend .list-default;
        @extend .list-default-width-sm;
    }
}
.action {
    &-container {
        display: inline-block;
        vertical-align: top;
    }
    &-default {
        .MuiMenu-paper .MuiList-root,
        .MuiList-root {
            @extend .list-default-width-sm;
        }
    }
}
.link {
    cursor: pointer;
}
.MuiAvatar-root {
    width: 30px;
    height: 30px;
}
.error-message {
    color: #ea5455;
    margin-bottom: $space;
}
.side-left {
    padding-left: $space2;
}
.side-right {
    padding-right: $space2;
}
.sides {
    @extend .side-left;
    @extend .side-right;
}
.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1301;
    > * + * {
        margin-top: 16px;
    }
}

.list-notification {
    width: 100%;
    max-width: 300px;
    @media (min-width: $sm) {
        max-width: 330px;
    }

    .list-item {
        padding-top: 10px;
        padding-bottom: 10px;

        &:hover {
            background-color: var(--mui-palette-secondary-main);
        }
    }
}

.main-content {
    min-height: 100vh;
    padding: 16px 30px 0;
    width: 100%;
    transition: margin-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

    @media (min-width: $md) {
        width: calc(100% - #{$sidebarWidth});
        margin-left: $sidebarWidth;
    }
}

.menu-button {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;  
    background-color: var(--mui-palette-primary-main);
    color: #ffffff;
}

.sidebar {
    
    &-top {
        padding-bottom: 7px;

        .logo {
            margin-top: $space2;
            margin-bottom: 13px;
        }
    }

    &-user {
        @include border-radius($borderRadius);
        border: 1px solid $borderColor;
        padding: 5px 7px;
        margin-bottom: 7px;
        background-color: var(--mui-palette-background-secondary);

        &-org {overflow: hidden;}

        .select-dropdown {
            position: relative;
            top: 2px;
            .MuiInputLabel-root {
                font-size: 0.625rem;
                color: var(--mui-palette-text-secondary);
                transform: none!important;
            }
            .MuiInputBase-root {
                margin-top: 0!important;
                
                .MuiInputBase-input {
                    font-size: $fontSize;
                    padding-bottom: 0;
                    padding-top: 16px;
                }
            }
        }
    }

    &-paper {
        width: $sidebarWidth;
        color: var(--mui-palette-text-primary);
        border-right-color: $borderColor;
        
        &.mini {
            @media (min-width: $md) {
                width: $sidebarMiniWidth;
                overflow: hidden;
                visibility: visible !important;
                transform: none !important;

                &:hover {
                    width: $sidebarWidth;
                    overflow: auto;
                }
                &:not(:hover) {
                    .menu-item-collapse-items {
                        display: none;
                    }
                }
            }
        }
    }

    &-content {
        height: 100%;
        padding: 0 $space2;
    }
}
.gradient {
    &-color1 {
        stop-color: var(--mui-palette-primary-main);
    }
    &-color2 {
        stop-color: var(--mui-palette-primary-main);
    }
}
.menu {
    &-container {
        height: 100%;

        .MuiList-root:first-child {
            padding-top: 0;
        }
    }
    &-group-title {
        font-size: 0.75rem;
        color: var(--mui-palette-text-secondary);
        padding: 0 $space2;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    &-divider {
        margin-top: 2px;
        margin-bottom: 10px;
    }

    &-item-focus {
        border-color: var(--mui-palette-primary-main);
        background-color: transparent;
        .menu-item-text {
            color: var(--mui-palette-text-primary);
        }

        .menu-item-icon {
            .MuiSvgIcon-root {
                color: var(--mui-palette-text-primary);
            }
            .fill-default {
                fill: var(--mui-palette-text-primary);
            }
        }

        .menu-item-icon-list {
            svg {
                color: var(--mui-palette-primary-main);
            }
        }
    }

    &-item-hover {
        @extend .menu-item-focus;
    }

    &-item-selected {
        @extend .menu-item-focus;
    }

    .menu-item {
        margin-bottom: 5px;
        align-items: center;
        padding-left: 8px;
        padding-right: 8px;
        @include border-radius($borderRadius);
        border: 1px solid transparent;

        .menu-item-icon {
            margin-top: auto;
            margin-bottom: auto;
            width: 25px;

            .MuiSvgIcon-root {
                font-size: 1.0625rem;
            }
            .fill-default {
                fill: var(--mui-palette-text-secondary);
            }
        }
        .menu-item-text {
            color: var(--mui-palette-text-secondary);
        }

        &:hover {
            @extend .menu-item-hover;
        }

        &.Mui-selected {
            @extend .menu-item-hover;

            &:hover {
                @extend .menu-item-hover;
            }
        }

        &.menu-item-item {
            &.Mui-selected {
                @extend .menu-item-selected;

                &:hover {
                    @extend .menu-item-selected;
                }
            }
        }

        &.job-passed {
            .menu-item-icon-list {
                svg {
                    color: #00c600;
                }
            }

            &.Mui-selected:hover {
                .menu-item-icon-list {
                    svg {
                        color: inherit;
                    }
                }
            }
        }
        &.job-failed {
            .menu-item-icon-list {
                svg {
                    color: #ff623c;
                }
            }

            &.Mui-selected:hover{
                .menu-item-icon-list {
                    svg {
                        color: inherit;
                    }
                }
            }
        }
    }
}

.form {
    .MuiInputLabel-root {
        @extend .text-default;
        font-weight: 500;
        color: var(--mui-palette-text-primary);
        padding-bottom: 2px;
    }

    &-label-input {
        height: 40px;
    }
}

.summary-item {
    .summary-label {
        color: var(--mui-palette-text-secondary);
    }

    .summary-value {
        font-size: 25px;
        color: var(--mui-palette-text-primary);

        &.debit {
            color: var(--mui-palette-orange-main);
        }
    }
}

.toast {
    &-info {
        .icon {
            color: var(--mui-palette-primary-main);
        }
    }

    &-warning {
        .icon {
            color: var(--mui-palette-warning-main);
        }
    }
}

.color {
    &-primary {
        color: var(--mui-palette-primary-main);
    }
    &-text-secondary {
        color: var(--mui-palette-text-secondary);
    }
    &-red {
        color: red;
    }
    &-link {
        color: #1EB0FF;
    }
}

.file-upload {
    background-color: #ffffff;
    border: 1px dashed var(--mui-palette-text-secondary);
    @include border-radius($borderRadius);
    position: relative;

    &.file-upload-normal {
        min-height: 150px;
        margin-bottom: 24px;
        padding: 15px;

        .file-drop {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            .file-drop-text {
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                @include translate(0, -50%);
            }
        }
    }

    &.file-upload-mini {
        max-width: 100%;
        padding: 5px;
        .file-upload-top {
            @extend .display-flex;
            -ms-flex-align: center;
            align-items: center;
        }
        .file-upload-action {
            padding: 0 5px;
        }

        .file-drop {
            @extend .flex-grow-1;
        }
        .files {
            @extend .flex-grow-1;
            padding: 0 5px;
        }
    }

    .file-upload-action {
        position: relative;
        z-index: 1;
    }

    .file-drop {
        &.active {
            border: 1px dashed var(--mui-palette-primary-main);
            @include border-radius($borderRadius);
        }

        .file-drop-text {
            @extend .text-default;
            color: var(--mui-palette-text-secondary);
            text-align: center;
        }
    }
}

.nowrap {
    white-space: nowrap;
}

.one-line {
    @extend .nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &-cell {
        max-width: 300px;
    }
}

.grid {
    .buttons {
        padding: 8px 0;
        min-height: 40px;
    }
    &-action {
        button {
            margin: 0 1px;
        }
    }
}

.row-link {
    position: absolute;
    left:0;right:0;top:0;bottom:0;
    &-container {
        position: relative;

        .action-container, .row-link-exception, .tooltip {
            position: relative;
            z-index: 1;
        }
    }
}

.item {
    &-default {
        border-left: 3px solid transparent;
    }
    &-error {
        border-left: 3px solid red;
    }
}

tr.row-selectable {
    @extend .item-default;

    &.is-focused {
        border-color: var(--mui-palette-secondary-main);
    }
    &.is-selected {
        border-color: var(--mui-palette-primary-main);
    }
    &.is-error {
        border-color: red;

        &::before {
            content: attr(title);
            color: red;
            display: block;
            position: absolute;
            padding: 0 5px;
            background: rgba(255,0,0,0.1);
        }
    }
    &.single {
        .txt-comment {
            width: 200px;
        }
    }
    &.dual {
        .comment {
            max-width: 100px;
        }
    }
}

.invisible {
    visibility: hidden;
}

.file {
    &-container {
        position: relative;
        display: block;
        /*width: 100%;
        padding: 0;*/
        overflow: hidden;
        
        &.mini {
            margin: -15px -15px 0;
        }

        &::before {
            display: block;
            content: "";            
        }

        &.with-files::before {
            padding-top: 141.428571%;
        }

        .file-viewer {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
    
            &.file-viewer-selected {
                z-index: 999;
            }
    
            &.image-viewer {
                background-color: #ffffff;
                img {
                    max-width: 100%;
                }
            }
    
            &.loading::before {
                content: "Loading...";
                display: block;
                padding: 15px;
                position: absolute;
            }
        }
    }
}

.pdf {
    &-scrollable {
        overflow-x: auto;
    } 

    &-content {
        width: 21cm;
        margin: 0 auto;
    }
}

.sort {
    cursor: pointer;
    svg {
        display: none;
        font-size: inherit;
        margin-left: 5px;
        color: var(--mui-palette-text-primary)
    }

    &.asc {
        .down {
            display: inline-block;
        }
    }

    &.desc, &.desc_nulls_last {
        .up {
            display: inline-block;
        }
    }
}

.report {
    #designer-host,
    #viewer-host {
        width: 100%;
        height: 100vh;
    }
}

.hidden {
    display: none;
}

.dialog {
    &-custom {
        .MuiDialog-paper {
            @media (min-width: $sm) {
                min-width: 500px;
            }
        }

        &-md {
            .MuiDialog-paper {
                @media (min-width: $md) {
                    min-width: 700px;
                }
            }
        }

        .MuiDialogActions-root {
            padding-left: 24px;
            padding-right: 24px;
        }

        .MuiDialogTitle-root {
            background: var(--mui-palette-primary-main);
            padding-top: 5px;
            padding-bottom: 5px;
            color: #ffffff;
        }

        .MuiDialogContent-root {
            padding-top: 20px;
        }
    }
}
.status {
    border: 1px solid #000000;
    width: 35px;
    height: 7px;
    font-size: 0;
    &-0 {
        background-color: $status0Color;
    }
    &-1 {
        background-color: $status1Color;
    }
    &-2 {
        background-color: $status2Color;
    }
    &-3 {
        background-color: $status3Color;
    }
    &-4 {
        background-color: $status4Color;
    }
    &-5 {
        background-color: $status5Color;
    }
}

.select-status {
    display: inline-flex;
    padding: 5px;
    @include border-radius($borderRadius);
    background-color: var(--mui-palette-background-secondary);
    .MuiButton-root {
        font-weight: 400;
        padding-top: 5px;
        padding-bottom: 5px;
        &.selected {
            background-color: var(--mui-palette-background-third);
        }
    }
}

.fieldset {
    border: 1px solid $borderColor;

    &-form {
        margin-left: -8px;
        margin-right: -8px;
        padding-left: 8px;
        padding-right: 8px;
    }
}

.is-default {
    color: var(--mui-palette-primary-main);
    font-weight: bold;
}

.ui-sortable {
    .handle {
        cursor: move;
    }
    .ui-sortable-helper {
        background-color: var(--mui-palette-secondary-light);
        *:not(.sortable-title) {
            visibility: hidden;
        }
        .sortable-title, .sortable-title * {
            visibility: visible;
        }
    }
}

.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
}

.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding: 0 0.5rem;
    color: #6c757d;
    content: "/";
}

.MuiTooltip-tooltip {
    .breadcrumb {
        display: block;
    }
    .breadcrumb-item + .breadcrumb-item::before {
        display: none;
    }
}

.icon-text {
    font-size: 1rem;
    vertical-align: top;
}

.drawer-base {
    .MuiDrawer-paper {
        width: calc(100% - 35px);
        padding: 11px $space2 $space2;

        @media (min-width: $md) {
            width: calc(100% - #{$sidebarWidth});
        }

        .card-main {
            overflow: visible;
        }
    }

    &.mini {
        .MuiDrawer-paper {
            @media (min-width: $lg) {
                width: 800px;
            }
        }
    }
}

.iframe-container {
    iframe {
        max-width: 100%;
    }
}

.img {
    &-default {
        max-width: 100%;
    }
}
.thumbnail {
    width: 150px;
}

.template-preview {
    border: 1px solid $borderColor;
    position: relative;
    transform-origin: 0 0;
    width:0;height:0;overflow: hidden;
    
    &-time {
        .MuiChip-root {
            margin-right: 5px;margin-bottom: 5px;
        }
    }
    .template-element {
        position: absolute;
        border-style: solid;
    }
    .template-text {
        overflow: hidden;
        display: flex;
    }
}

.card-item {
    background-color: var(--mui-palette-primary-light);
}
.moving {
    transition: transform 0.3s ease-out;
}
.color-picker {
    >div {
        width: 100%;
    }
    .ColorPicker-MuiFormControl-root {
        flex-grow: 1;
    }
}
.requirements {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline;
    &::before {
        content: '(';
        display: inline;
        padding-left: 3px;
    }
    &::after {
        content: ')';
        display: inline;
    }
    li {
        display: inline;
    }
    li+li::before {
        content: ', ';
        display: inline;
    }
}

.required::after {
    content: "*";
    display: inline;
    color: red;
    padding-left: 2px;
}